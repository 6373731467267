import { Accordion, AccordionSummary, Checkbox, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { backendApiClient } from '../../../apiClient'
import { useSnackbar } from 'notistack'
import { isEmpty, sortPoints } from '../../../utils'
import { LoadingBackdrop } from '../../../components'

const manufacturers = {
  abl: 'ABL',
  alfen: 'Alfen',
  flotteladen: 'Flotteladen',
  amperfied: 'AMP',
  mennekes: 'Mennekes',
}

const ChargingPointInfo = ({ item, isChecked, onChange, disabled }) => {
  return (
    <Grid container
          direction="row"
          alignItems="center"
    >
      <Grid item xs={5} sx={{ paddingLeft: 3 }}>
        <Typography sx={[disabled ? { color: 'text.disabled' } : { color: 'text.primary' }]}>
          {item.name}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography sx={[disabled ? { color: 'text.disabled' } : { color: 'text.primary' }]}>
          {manufacturers[item.manufacturer]}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography sx={[disabled ? { color: 'text.disabled' } : { color: 'text.primary' }]}>
          {item.currentStatus?.info?.firmware}</Typography>
      </Grid>
      <Grid item xs={1}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
          <Checkbox
            sx={{ padding: 0 }}
            id={`select-${item.id}`}
            disabled={disabled}
            color="primary"
            checked={isChecked}
            onClick={(e) => onChange(item, e.target.checked)}
          />
        </div>
      </Grid>
    </Grid>
  )
}

const ChargingPointSelectionAccordion = ({ station, changeSelectedState, manufacturer }) => {
  const [accordionOpen, setAccordionOpen] = React.useState(false)
  const [chargingPoints, setChargingPoints] = useState([])
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const [selectableChargingPoints, setSelectableChargingPoints] = useState({})
  const disabled = station.connectionStatus !== 'ONLINE'

  const CHARGING_POINTS_URL = `/api/client/${station.clientId}/chargingpoints`

  const toggleAccordion = () => {
    const newToggleState = !accordionOpen
    setAccordionOpen(newToggleState)

    if (newToggleState && chargingPoints.length === 0) {
      fetchChargingPoints()
    }
  }

  const fetchChargingPoints = () => {
    setLoading(true)
    backendApiClient.get(CHARGING_POINTS_URL)
      .then((response) => {
        const filteredCPs = response.filter(item => manufacturers[item.manufacturer] === manufacturer && isEmpty(item.parentId))
        sortPoints(filteredCPs)
        setChargingPoints(filteredCPs)

        const cpList = {}
        filteredCPs.forEach(cp => cpList[cp.id] = false)
        setSelectableChargingPoints(cpList)
      })
      .catch(() => {
        enqueueSnackbar('Die Daten konnten nicht geladen werden!', { variant: 'error' })
      })
      .finally(() => setLoading(false))
  }

  const onChange = (item, checked) => {
    setSelectableChargingPoints({
      ...selectableChargingPoints,
      [item.id]: checked,
    })
    changeSelectedState(station.clientId, item, checked)
  }

  const onChangeAll = (checked) => {
    const tmpSelectedChargingPoints = ({ ...selectableChargingPoints })
    Object.keys(tmpSelectedChargingPoints)
      .forEach(e => tmpSelectedChargingPoints[e] = checked)
    setSelectableChargingPoints(tmpSelectedChargingPoints)

    Object.keys(tmpSelectedChargingPoints)
      .forEach(selCp =>
        chargingPoints
          .filter(cp => cp.id === parseInt(selCp))
          .forEach(cp => changeSelectedState(station.clientId, cp, checked)))
  }

  const isCheckedAll = () => {
    return !isEmpty(selectableChargingPoints) && Object.values(selectableChargingPoints)
      .every(v => v === true)
  }

  return (
    <React.Fragment>
      <LoadingBackdrop open={loading}/>
      <Accordion
        expanded={accordionOpen}
        sx={{
          boxShadow: 'none',
          '&:before': {
            display: 'none',
          },
          '&.Mui-expanded': {
            marginTop: '0px !important',
            marginBottom: '0px !important',
          },
        }}
      >
        <AccordionSummary
          disabled={disabled}
          expandIcon={<ExpandMoreIcon id={`expand-less-${station.id}`} sx={{ paddingTop: 0 }} onClick={toggleAccordion}/>}
          sx={(theme) => ({
            height: '64px',
            flexDirection: 'row-reverse',
            borderBottom: `1pt solid ${theme.palette.border.light}`,
            paddingLeft: 0,
            paddingRight: 0,
            '& .MuiAccordionSummary-expandIconWrapper': { marginLeft: -0.5 },
          })}
        >
          <Grid container
                direction="row"
                alignItems="center"
          >
            <Grid item xs={11}>
              <Typography onClick={toggleAccordion}>{station.name} {disabled && ' (Offline)'}</Typography>
            </Grid>
            <Grid item xs={1}>
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}>
                <Checkbox
                  sx={{ padding: 0, marginRight: 0.2 }}
                  id={`selectAll`}
                  color="primary"
                  disabled={isEmpty(selectableChargingPoints)}
                  checked={isCheckedAll()}
                  onClick={(e) => onChangeAll(e.target.checked)}
                />
              </div>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            backgroundColor: 'primary.childBackdrop',
            padding: 0,
          }}
        >
          {chargingPoints.length > 0 ? (
            chargingPoints.map((item) => {
              return (
                <div
                  key={item.id}
                  style={{
                    paddingBottom: 15,
                    paddingTop: 15,
                  }}>
                  <ChargingPointInfo item={item} onChange={onChange} isChecked={selectableChargingPoints[item.id]}/>
                </div>
              )

            })) : <Typography padding={1}>{!loading && 'Keine Ladepunkte verfügbar'}</Typography>

          }
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  )
}

export default ChargingPointSelectionAccordion