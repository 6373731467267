import React, { useEffect, useState } from 'react'
import { useRouteLoaderData } from 'react-router'
import { useFormik } from 'formik'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { Button, Grid, IconButton, Tooltip } from '@mui/material'
import DialogActions from '@mui/material/DialogActions'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import CustomFormikSwitch from '../../../../components/crud/widgets/components/CustomFormikSwitch'
import CustomFormikSelectField from '../../../../components/crud/widgets/components/CustomFormikSelectField'
import CustomFormikRadioButtons from '../../../../components/crud/widgets/components/CustomFormikRadioButtons'
import CustomFormikTextField from '../../../../components/crud/widgets/components/CustomFormikTextField'
import IpAddressWidgetV2 from '../../../../components/crud/widgets/IpAddressWidgetV2'
import { LoadingBackdrop, SubmitFab } from '../../../../components'
import { chargingPointVersionTwoValidation } from './ChargingPointValidation'

const ChargingPointVersionTwoWidget = ({ save, chargingPoint, disabledOnEdit, loading }) => {
  const [open, setOpen] = useState(false)
  const { settings } = useRouteLoaderData('settingsRoot')
  const { customer } = useRouteLoaderData('clientRoot')

  const manufacturers = [
    {
      value: 'abl',
      label: 'ABL',
    },
    {
      value: 'alfen',
      label: 'Alfen',
    },
    {
      value: 'mennekes',
      label: 'Mennekes',
    },
    {
      value: 'flotteladen',
      label: 'Flotteladen',
    },
    {
      value: 'amperfied',
      label: 'Amperfied',
    },
  ]

  const chargingBoxTypes = [
    {
      name: 'single',
      label: 'Single',
    },
    {
      name: 'twin',
      label: 'Twin',
    },
  ]

  const formik = useFormik({
    initialValues: {
      controllerConnectionStatus: 'CONNECTED',
      manufacturer: manufacturers[0].value,
      chargingBoxType: chargingBoxTypes[0].name,
      activated: false,
      twinName: '',
      connectorId: 1,
      controllerId: 1,
      maxPower: 11,
      name: 'LP ' + (settings.chargingPoints ? settings.chargingPoints?.length + 1 : 1),
      ordinalNumber: 1,
      pvGuided: false,
      ipAddress: '0.0.0.0',
      serialNumber: '',
      chargePointId: '',
      areaId: 1,
      phase: null,
      type: 'TYP2_THREE_PHASE',
    },
    validationSchema: chargingPointVersionTwoValidation(settings.chargingPoints),
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    onSubmit: (values) => {
      save(values)
    },
  })

  const handleDialogClose = () => {
    setOpen(false)
  }

  const handleDialogDisconnect = () => {
    formik.setFieldValue('controllerConnectionStatus', 'DISCONNECTED')
    handleDialogClose()
  }

  const switchLMSConnectionStatus = (value) => {
    if (value === false) {
      setOpen(true)
      return
    }
    formik.setFieldValue('controllerConnectionStatus', 'CONNECTED')
  }

  const twinCompatible = () => {
    return formik.values.manufacturer === 'abl' || formik.values.manufacturer === 'alfen' || formik.values.manufacturer === 'mennekes'
  }

  const selectedTwin = () => {
    return twinCompatible() && formik.values.chargingBoxType === 'twin'
  }

  const generateIp = () => {
    const lastDigit = settings.chargingPoints ? settings.chargingPoints?.length + 1 : 1
    formik.setFieldValue('ipAddress', '192.168.44.' + (lastDigit))
  }

  useEffect(() => {
    if (chargingPoint && Object.values(chargingPoint).length > 0) {
      formik.setValues(chargingPoint)
      if (!chargingPoint.controllerConnectionStatus) {
        formik.setFieldValue('controllerConnectionStatus', 'CONNECTED')
      }
      const extenders = chargingPoint.extenders
      if (extenders && Object.values(extenders).length > 0) {
        formik.setFieldValue('twinName', extenders[0].name)
        formik.setFieldValue('chargingBoxType', 'twin')
      } else {
        formik.setFieldValue('chargingBoxType', 'single')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chargingPoint])

  return (
    <form onSubmit={formik.handleSubmit}>
      <LoadingBackdrop open={loading}/>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle>
          {'WARNUNG'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <p>Durch das <strong>Trennen</strong> des Ladepunktes, <br/>kann <strong>kein
              Ladevorgang</strong> durchgeführt werden.</p>
            <p>Der Ladepunkt wird aus der Überprüfung <strong>ausgeschlossen</strong>.</p>
            Möchten Sie diesen Ladepunkt trennen?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ color: 'primary' }}
            onClick={() => {
              handleDialogClose()
            }}
            id={'dialog-lms-connection-cancel-button'}
            autoFocus
            color="primary"
          >
            {'Abbrechen'}
          </Button>
          <Button
            onClick={() => {
              handleDialogDisconnect()
            }}
            id={'dialog-lms-connection-disconnect-button'}
            autoFocus
            color="primary"
          >
            {'Trennen'}
          </Button>
        </DialogActions>
      </Dialog>
      <Grid
        container
        sx={{
          flexGrow: 1,
          marginTop: -1,
        }}
        spacing={3}
      >
        <Grid item>
          <CustomFormikSwitch
            value={formik.values.controllerConnectionStatus === 'CONNECTED'}
            onChange={(event, value) => switchLMSConnectionStatus(value)}
            label={'Verbindung zum LMS'}
            id={'lms-connected-button'}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <CustomFormikSelectField
            value={formik.values.manufacturer}
            values={manufacturers}
            onChange={formik.handleChange}
            id={'manufacturer'}
            label={'Hersteller'}
            disabled={disabledOnEdit}
            error={Boolean(formik.errors?.manufacturer)}
            helperText={formik.errors?.manufacturer || ''}
          />
        </Grid>
        {twinCompatible() &&
          <Grid
            item
            xs={12}
          >
            <CustomFormikRadioButtons
              input={chargingBoxTypes}
              row={true}
              paddingBetween={10}
              disabled={disabledOnEdit}
              handleChange={formik.handleChange}
              id={'chargingBoxType'}
              value={formik.values.chargingBoxType}
            />
          </Grid>
        }
        <Grid
          item
          xs={12}
          sm={selectedTwin() ? 6 : 12}
        >
          <CustomFormikTextField
            value={formik.values.name}
            onChange={formik.handleChange}
            id={'name'}
            label={selectedTwin() ? 'Linke Steckdose' : 'Name'}
            error={Boolean(formik.errors?.name)}
            helperText={formik.errors?.name || ''}
          />
        </Grid>
        {selectedTwin() &&
          <Grid
            item
            xs={12}
            sm={6}
          >
            <CustomFormikTextField
              value={formik.values.twinName}
              onChange={formik.handleChange}
              id={'twinName'}
              label={'Rechte Steckdose'}
              error={Boolean(formik.errors?.twinName)}
              helperText={formik.errors?.twinName || ''}
            />
          </Grid>
        }
        <Grid
          item
          xs={12}>
          <CustomFormikSelectField
            value={formik.values.areaId}
            values={settings.areas.map((area) => ({ value: area.id, label: area.name }))}
            onChange={formik.handleChange}
            id={'areaId'}
            label={'Zone'}
            error={Boolean(formik.errors?.areaId)}
          />
        </Grid>
        <Grid
          item
          container
          xs={12}>
          <Grid
            item
            xs={3}
          >
            <CustomFormikTextField
              type={formik.values.manufacturer === 'flotteladen' ? 'number' : 'string'}
              value={formik.values.serialNumber}
              onChange={formik.handleChange}
              id={'serialNumber'}
              label={'Seriennummer'}
              error={Boolean(formik.errors?.serialNumber)}
              helperText={formik.errors?.serialNumber || ''}
            />
          </Grid>
        </Grid>
        {twinCompatible() &&
          <Grid
            item
            container
            xs={12}>
            <Grid
              item
              xs={3}
            >
              <CustomFormikTextField
                value={formik.values.chargePointId}
                onChange={formik.handleChange}
                id={'chargePointId'}
                label={'ChargePointId'}
                error={Boolean(formik.errors?.chargePointId)}
                helperText={formik.errors?.chargePointId || ''}
              />
            </Grid>
          </Grid>
        }
        <Grid
          item
          container
          xs={12}
          alignItems={'center'}
          spacing={2}
        >
          <Grid item sx={{
            display: 'flex',
            flexDirection: 'row',
          }}>
            <IpAddressWidgetV2
              id={'ipAddress'}
              title={'IP-Adresse'}
              value={formik.values.ipAddress}
              onChange={(ip) => formik.setFieldValue('ipAddress', ip)}
              error={Boolean(formik.errors?.ipAddress)}
              helperText={formik.errors?.ipAddress || ''}
            />
            <div style={{ paddingTop: 10 }}>
              <Tooltip title={'Generieren'}>
                <IconButton
                  style={{ paddingLeft: 0 }}
                  onClick={() => generateIp()}
                  size="large">
                  <AutorenewIcon/>
                </IconButton>
              </Tooltip>
            </div>
          </Grid>
        </Grid>
        {customer.hasPv &&
          <Grid item>
            <CustomFormikSwitch
              value={formik.values.pvGuided}
              onChange={formik.handleChange}
              label={'PV-Überschussladung'}
              id={'pvGuided'}
            />
          </Grid>
        }
      </Grid>
      <SubmitFab show={true}/>
    </form>
  )
}

export default ChargingPointVersionTwoWidget