import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import { Grid } from '@mui/material'
import CustomFormikSelectField from '../../../../components/crud/widgets/components/CustomFormikSelectField'
import CustomFormikTextField from '../../../../components/crud/widgets/components/CustomFormikTextField'
import * as yup from 'yup'
import { ERROR_NAME_EMPTY } from '../lmsVersionTwo/ChargingPointVersionTwoConstants'
import { SubmitFab } from '../../../../components'

export const extenderValidation = yup.object({
  name: yup.string()
    .required(ERROR_NAME_EMPTY),
})

const ChargingPointExtenderWidget = ({ save, chargingPoint }) => {

  const manufacturers = [
    {
      value: 'abl',
      label: 'ABL',
    },
    {
      value: 'alfen',
      label: 'Alfen',
    },
    {
      value: 'mennekes',
      label: 'Mennekes',
    },
    {
      value: 'flotteladen',
      label: 'Flotteladen',
    },
  ]

  const formik = useFormik({
    initialValues: {
      manufacturer: 'ABL',
      name: '',
    },
    validationSchema: extenderValidation,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    onSubmit: (values) => {
      save(values)
    },
  })

  useEffect(() => {
    if (chargingPoint && Object.values(chargingPoint).length > 0) {
      formik.setValues(chargingPoint)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chargingPoint])

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid
        container
        sx={{
          flexGrow: 1,
          marginTop: 1,
        }}
        spacing={2}
      >
        <Grid
          item
          xs={12}
        >
          <CustomFormikSelectField
            value={formik.values.manufacturer}
            values={manufacturers}
            id={'manufacturer'}
            label={'Hersteller'}
            disabled={true}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
        >
          <CustomFormikTextField
            value={formik.values.name}
            onChange={formik.handleChange}
            id={'name'}
            label={'Name'}
            error={Boolean(formik.errors?.name)}
            helperText={formik.errors?.name || ''}
          />
        </Grid>
      </Grid>
      <SubmitFab show={true}/>
    </form>
  )
}

export default ChargingPointExtenderWidget